

















































































































import { Inject } from '@cds/common';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { statisticsApi } from '@/services/statistics-service';//新的接口 换这里
import { Message } from 'element-ui';
@Component({
    components: {

    }
})
export default class personStatistics extends Vue {
    [x: string]: any;
    public form: any = {
        size: 20, current: 1, mailStatus: '1', replyStatus: ''
    };
    public datacreateTime: any[] = [];
    private tableData: any[] = [
    ];
    public doStatus: any[] = [
        // {
        //     name: '全部',
        //     id: '',
        // },
        {
            name: '成功',
            id: '1',
        },
        // {
        //     name: '失败',
        //     id: '2',
        // },
        // {
        //     name: '待发送',
        //     id: '0',
        // }
        // ,
        // {
        //     name: '发送中',
        //     id: '3',
        // }
    ];
    public callStatus: any[] = [
        {
            name: '全部',
            id: '',
        },
        {
            name: '已回复',
            id: '1',
        },
        {
            name: '未回复',
            id: '0',
        }
    ];
    public visible: Boolean = false;
    public loading: Boolean = true;
    public errorMsg: String = '';
    @Inject(statisticsApi) private statisticsApi!: statisticsApi;
    public async created(): Promise<void> {
        await this.getList();
    }
    public search() {
        this.form.current = 1;
        this.getList()
    }
    // 重置
    public reset() {
        this.form = {
            size: 20,
            current: 1,
            mailStatus: '',
            replyStatus: ''
        };
        this.datacreateTime = [];
        this.getList();
    }
    //分页相关
    public handleSizeChange(size: number) {
        this.form.size = size;
        this.getList();
    }
    public handleCurrentChange(current: number) {
        this.form.current = current;
        this.getList();
    }
    //获取数据源
    public async getList() {
        var that = this;
        that.loading = true;
        if (that.datacreateTime && that.datacreateTime.length > 0) {
            that.form.queryDateEnd = that.datacreateTime[1]
            that.form.queryDateStart = that.datacreateTime[0]
        } else {
            delete that.form.queryDateStart
            delete that.form.queryDateEnd
        }
        const res = await that.statisticsApi.getMiList(that.form);
        that.tableData = res.records;
        console.log(res.records)
        for (var i = 0; i < res.records.length; i++) {
            if (res.records[i].queryContent != '' && res.records[i].queryContent != null && res.records[i].queryContent != undefined) {
                if (res.records[i].queryContent.length > 5) {
                    res.records[i].queryContentNew = res.records[i].queryContent.slice(0, 5) + '......';
                } else if (res.records[i].queryContent.length <= 5) {
                    res.records[i].queryContentNew = res.records[i].queryContent
                }
            } else { res.records[i].queryContentNew = res.records[i].queryContent }
        }
        that.form.total = res.total;
        that.form.pages = res.pages;
        that.form.size = res.size;
        that.form.current = res.current;
        that.loading = false;
        if (res.records.length <= 0) {
            that.form.pages = 1;
            that.form.size = 20;
            that.form.current = 1;
            that.form.total = 0;
        }
    }
    public openMsg(msg: String): void {
        this.errorMsg = msg;
        this.visible = true;
    }
    public async doAnything(item: any): Promise<void> {
        var that = this, data_ = {
            form_id: 0
        };
        that.loading = true;
        data_.form_id = item.id
        const res = await that.statisticsApi.MisendAgain(data_);
        this.$message({
            message: '邮件会在5分钟之内重新发送，请耐心等待',
            type: 'success'
        });
        that.loading = false;
        that.getList();

    }

    private mounted() {
        document.onkeydown = (e) => {
            let key = (window.event as any).keyCode;
            if (key == 13) {
                this.search()
            }
        }
    }
  //导出
  public async exportClick() {
    delete this.form.current;
    delete this.form.pages;
    delete this.form.size;
    delete this.form.total;
    var that = this
    if (that.tableData.length <= 0) {
      this.$message({
        type: 'error',
        message: '当前无下载内容！'
      })
      return;
    } else {
      this.loading = true;
      const res = this.statisticsApi.mimanageExportReport({...this.form});
      this.loading = false;
    }
  }
    public copyUrl(val: String) {
        var oInput: any = document.createElement('input');
        oInput.value = val;
        document.body.appendChild(oInput);
        oInput.select();
        document.execCommand("Copy");
        this.$message({
            type: 'success',
            message: '复制成功'
        });
        oInput.remove();
    }
}
